import { useState } from 'react';
import { Row, Col, Form, Nav } from 'react-bootstrap';
import { StyledNav } from './styles';
import ModulesCubageTable from './modules';
import InvertersCubageTable from './Inverters';
import CablesCubageTable from './Cables';
import StructuresCubageTable from './Structures';

export default function CubageData() {
    const [tab, setTab] = useState('modules');

    return (
        <>
            <Form.Group controlId="formConfiguration">
                <Row className="mb-3">
                    <Col md={8}>
                        <StyledNav
                            variant="tabs"
                            activeKey={tab}
                            onSelect={(selectedKey: string) =>
                                setTab(selectedKey)
                            }
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="inverters">
                                    Inversores
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="modules">Módulos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="cables">Cabos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="structures">
                                    Estruturas
                                </Nav.Link>
                            </Nav.Item>
                        </StyledNav>
                    </Col>
                </Row>

                {tab === 'inverters' && <InvertersCubageTable />}

                {tab === 'modules' && <ModulesCubageTable />}

                {tab === 'cables' && <CablesCubageTable />}

                {tab === 'structures' && <StructuresCubageTable />}
            </Form.Group>
        </>
    );
}
