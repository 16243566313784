import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { useState, useEffect, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { StyledRow, StyledTitle } from './styles';
import removeNonNumericChars from '../../../../../utils/removeNonNumericChars';
import CubageHttpService from '../../../../../services/http/cubage-http';
import ProductGroup from '../../../../../constants/ProductGroup';
import handleResponseError from '../../../../../utils/handleResponseError';
import InvertersCubage from '../../../../../shared/interfaces/inverters-cubage.interface';

interface FormData {
    erpProductCode: string;
    cubage: string;
}

interface InvertersCubageModalProps {
    selectedInverter: InvertersCubage | null;
    refetch: any;
    show: boolean;
    onHide: () => void;
}

export default function InvertersCubageModal({
    onHide,
    refetch,
    selectedInverter,
    show,
}: InvertersCubageModalProps) {
    const [isDisabled, setDisabled] = useState(true);
    const { register, watch } = useForm<FormData>({
        defaultValues: {
            erpProductCode: selectedInverter?.erpProductCode || '',
            cubage: selectedInverter ? selectedInverter.cubage.toString() : '',
        },
    });

    const formData = watch();

    const updateMutation = useMutation(
        async () => {
            if (!selectedInverter) {
                throw new Error(
                    'Cubagem do inversor não encontrado para atualizar',
                );
            }

            const invertersCuabage = {
                erpProductCode: formData?.erpProductCode,
                cubage: Number(formData?.cubage),
            };

            await CubageHttpService.update(
                ProductGroup.InvertersCubage,
                selectedInverter.id,
                invertersCuabage,
            );

            refetch();
        },
        {
            onError: (error: any) => {
                handleResponseError(
                    error,
                    'Erro ao salvar configuração de cubagem',
                );
            },
            onSuccess: async () => {
                toast.success('Configuração de cubagem salva com sucesso');
                onHide();
            },
        },
    );

    const createMutation = useMutation(
        async () => {
            const invertersCubage: any = {
                erpProductCode: formData?.erpProductCode,
                cubage: Number(formData?.cubage),
            };

            await CubageHttpService.create(
                ProductGroup.InvertersCubage,
                invertersCubage,
            );
            refetch();
        },
        {
            onError: (error: any) => {
                handleResponseError(
                    error,
                    'Erro ao salvar cubagem do inversor',
                );
            },
            onSuccess: async () => {
                toast.success('Cubagem do inversor salva com sucesso');
                onHide();
            },
        },
    );

    useEffect(() => {
        if (
            formData?.erpProductCode?.length > 0 &&
            formData?.cubage.length > 0
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formData]);

    const handleChangeErpCode = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = removeNonNumericChars(e.target.value);
    };

    const isLoading = updateMutation.isLoading || createMutation.isLoading;

    return (
        <Modal
            className="modal-dialog-scrollable"
            show={show}
            onHide={onHide}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title
                    className="ml-3 mr-3"
                    id="contained-modal-title-vcenter"
                >
                    Cubagem do inversor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-100">
                <StyledRow className="ml-0 mt-2 mr-1">
                    <Col>
                        <StyledTitle>Código ERP do inversor</StyledTitle>
                    </Col>
                </StyledRow>
                <Row className="ml-0 mt-2 mr-1">
                    <Col>
                        <Form.Control
                            ref={register}
                            name="erpProductCode"
                            type="string"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                            onChange={handleChangeErpCode}
                        />
                    </Col>
                </Row>

                <StyledRow className="ml-0 mt-4 mr-1">
                    <Col>
                        <StyledTitle>Cubagem do inversor</StyledTitle>
                    </Col>
                </StyledRow>
                <Row className="ml-0 mt-2 mr-1">
                    <Col>
                        <Form.Control
                            ref={register}
                            name="cubage"
                            type="number"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                        />
                    </Col>
                </Row>
                <StyledRow className="ml-0 mt-5 mr-1">
                    <Col sm={6} />
                    <Col>
                        <Button
                            className="w-100 float-right"
                            variant="outline-primary"
                            onClick={() => {
                                onHide();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="w-100 float-right"
                            onClick={() => {
                                if (selectedInverter) {
                                    updateMutation.mutate();
                                } else {
                                    createMutation.mutate();
                                }
                            }}
                            disabled={isDisabled}
                        >
                            Salvar{'   '}
                            {isLoading && (
                                <Spinner animation="border" size="sm" />
                            )}
                        </Button>
                    </Col>
                </StyledRow>
            </Modal.Body>
            <Modal.Footer className="ml-3 mr-3 mb-2" />
        </Modal>
    );
}
