import ProductGroup from '../../constants/ProductGroup';
import Request from './request';

export interface ListCubageParams {
    skip?: number;
    take?: number;
    term?: string;
}

export default class CubageHttpService {
    public static create(productGroup: ProductGroup, data: {}) {
        return Request.post(`${productGroup}`, data);
    }

    public static update(productGroup: ProductGroup, id: number, data: {}) {
        return Request.patch(`${productGroup}/${id}`, data);
    }

    public static delete(productGroup: ProductGroup, id: number) {
        return Request.del(`${productGroup}/${id}`);
    }

    public static readMany(
        productGroup: ProductGroup,
        params: ListCubageParams,
    ) {
        return Request.get(`${productGroup}`, params);
    }
}
