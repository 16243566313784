import { useState, useEffect } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { StyledRow, StyledTitle } from './styles';
import CubageHttpService from '../../../../../services/http/cubage-http';
import ProductGroup from '../../../../../constants/ProductGroup';
import handleResponseError from '../../../../../utils/handleResponseError';
import CablesCubage from '../../../../../shared/interfaces/cables-cubage.interface';

interface FormData {
    limitInMeters: string;
    cubage: string;
}

interface CablesCubageModalProps {
    selectedCable: CablesCubage | null;
    refetch: any;
    show: boolean;
    onHide: () => void;
}

export default function CablesCubageModal({
    onHide,
    refetch,
    selectedCable,
    show,
}: CablesCubageModalProps) {
    const [isDisabled, setDisabled] = useState(true);
    const { register, watch } = useForm<FormData>({
        defaultValues: {
            limitInMeters: selectedCable
                ? selectedCable.limitInMeters.toString()
                : '',
            cubage: selectedCable ? selectedCable.cubage.toString() : '',
        },
    });

    const formData = watch();

    const updateMutation = useMutation(
        async () => {
            if (!selectedCable) {
                throw new Error(
                    'Cubagem do cabo não encontrado para atualizar',
                );
            }

            const cables: any = {
                limitInMeters: Number(formData?.limitInMeters),
                cubage: Number(formData?.cubage),
            };

            await CubageHttpService.update(
                ProductGroup.CablesCubage,
                selectedCable.id,
                cables,
            );

            refetch();
        },
        {
            onError: (error: any) => {
                handleResponseError(error, 'Erro ao salvar cubagem do cabo');
            },
            onSuccess: async () => {
                toast.success('Observação salva com sucesso');
                onHide();
            },
        },
    );

    const createMutation = useMutation(
        async () => {
            const Cables: any = {
                limitInMeters: Number(formData?.limitInMeters),
                cubage: Number(formData?.cubage),
            };

            await CubageHttpService.create(ProductGroup.CablesCubage, Cables);
            refetch();
        },
        {
            onError: (error: any) => {
                handleResponseError(error, 'Erro ao salvar cubagem do cabo');
            },
            onSuccess: async () => {
                toast.success('Cubagem do cabo salva com sucesso');
                onHide();
            },
        },
    );

    useEffect(() => {
        if (
            formData?.limitInMeters?.length > 0 &&
            formData?.cubage?.length > 0
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formData]);

    const isLoading = updateMutation.isLoading || createMutation.isLoading;

    return (
        <Modal
            className="modal-dialog-scrollable"
            show={show}
            onHide={onHide}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title
                    className="ml-3 mr-3"
                    id="contained-modal-title-vcenter"
                >
                    Cubagem do cabo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-100">
                <StyledRow className="ml-0 mt-4 mr-1">
                    <Col>
                        <StyledTitle>Limite em metros</StyledTitle>
                    </Col>
                </StyledRow>
                <Row className="ml-0 mt-2 mr-1">
                    <Col>
                        <Form.Control
                            ref={register}
                            name="limitInMeters"
                            type="number"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                        />
                    </Col>
                </Row>
                <StyledRow className="ml-0 mt-4 mr-1">
                    <Col>
                        <StyledTitle>Cubagem do cabo</StyledTitle>
                    </Col>
                </StyledRow>
                <Row className="ml-0 mt-2 mr-1">
                    <Col>
                        <Form.Control
                            ref={register}
                            name="cubage"
                            type="number"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                        />
                    </Col>
                </Row>
                <StyledRow className="ml-0 mt-5 mr-1">
                    <Col sm={6} />
                    <Col>
                        <Button
                            className="w-100 float-right"
                            variant="outline-primary"
                            onClick={() => {
                                onHide();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="w-100 float-right"
                            onClick={() => {
                                if (selectedCable) {
                                    updateMutation.mutate();
                                } else {
                                    createMutation.mutate();
                                }
                            }}
                            disabled={isDisabled}
                        >
                            Salvar{'   '}
                            {isLoading && (
                                <Spinner animation="border" size="sm" />
                            )}
                        </Button>
                    </Col>
                </StyledRow>
            </Modal.Body>
            <Modal.Footer className="ml-3 mr-3 mb-2" />
        </Modal>
    );
}
