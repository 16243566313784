import { useRef, useState } from 'react';
import { Button, Col, Dropdown, Row, Spinner, Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useInfiniteQuery, useMutation } from 'react-query';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';
import NotificationIcon from '../../../../constants/notificationIcon';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import { StyledTh } from '../styles';
import CablesCubage from '../../../../shared/interfaces/cables-cubage.interface';
import CablesFilterSection from './FilterSection';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import CablesCubageModal from './CablesModal';
import CubageHttpService, {
    ListCubageParams,
} from '../../../../services/http/cubage-http';
import ProductGroup from '../../../../constants/ProductGroup';
import handleResponseError from '../../../../utils/handleResponseError';

export interface CablesFilterProps {
    filterTerm: string;
}

export default function CablesCubageTable() {
    const rowsPerPage = 20;
    const [showAddCablesModal, setShowAddCablesModal] =
        useState<boolean>(false);
    const [showRemoveCablesModal, setShowRemoveCablesModal] =
        useState<boolean>(false);
    const [selectedCable, setSelectedCable] = useState<CablesCubage | null>(
        null,
    );

    const form = useForm<CablesFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
        },
    });

    const formData = form.watch();
    const termTrimmed = formData.filterTerm.trim();

    const CablesQuery = useInfiniteQuery({
        queryKey: ['cables-cubage', termTrimmed],
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: async ({ pageParam = 0 }) => {
            const params: ListCubageParams = {
                skip: pageParam,
                take: rowsPerPage,
                term: termTrimmed || '',
            };

            const response = await CubageHttpService.readMany(
                ProductGroup.CablesCubage,
                params,
            );

            return {
                data: response.data.results,
                currentPage: pageParam,
                pages: Math.ceil(response.data.metadata.total / rowsPerPage),
            };
        },
        getNextPageParam: ({ currentPage, pages }) =>
            currentPage + 1 < pages ? currentPage + 1 : null,
        onError: (err) => {
            handleResponseError(err, 'Erro ao buscar cabos');
        },
    });

    const target = useRef(null);

    useIntersectionObserver({
        target,
        onIntersect: CablesQuery.fetchNextPage,
        enabled: CablesQuery.hasNextPage,
    });

    const showLoading =
        CablesQuery.isLoading ||
        CablesQuery.isFetchingNextPage ||
        !CablesQuery.isFetched;

    const deleteMutation = useMutation(
        async () => {
            if (!selectedCable?.id) {
                throw new Error('Nenhum cabo selecionado para exclusão');
            }

            const response = await CubageHttpService.delete(
                ProductGroup.CablesCubage,
                selectedCable?.id,
            );

            return response.data;
        },
        {
            onError: (error: any) => {
                handleResponseError(error, 'Erro ao excluir cabo');
            },
            onSuccess: async () => {
                toast.success('Cabo removido com sucesso');

                CablesQuery.refetch();
            },
        },
    );

    const CableCubages = CablesQuery.data?.pages.reduce(
        (accumulator, current) => accumulator.concat(current.data),
        [] as CablesCubage[],
    );

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Cubagem dos cabos
                    </StyledPageTitle>
                </Col>
                <Button
                    className="float-right w-20 m-2"
                    style={{ width: '180px' }}
                    onClick={() => {
                        setShowAddCablesModal(true);
                    }}
                >
                    Nova configuração
                </Button>
            </Row>
            <CablesFilterSection control={form.control} />
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Limite em metros</StyledTh>
                            <StyledTh>Cubagem</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {CableCubages &&
                            CableCubages.map((CableCubage: CablesCubage) => (
                                <tr key={CableCubage.id}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={CableCubage?.id.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={CableCubage.limitInMeters.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={CableCubage.cubage.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Dropdown key="left">
                                            <Dropdown.Toggle
                                                bsPrefix="nexen"
                                                className="mt-n3 mb-n3"
                                                as={Button}
                                                variant="text"
                                            >
                                                <ReactMoreIcon
                                                    fill="#bdbdbd"
                                                    width="10"
                                                    height="20"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedCable(
                                                            CableCubage,
                                                        );

                                                        setShowAddCablesModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedCable(
                                                            CableCubage,
                                                        );

                                                        setShowRemoveCablesModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Remover
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Row>
            <Row className="mx-4" ref={target}>
                <br />
                {showLoading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {CableCubages?.length === 0 && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showAddCablesModal && (
                <CablesCubageModal
                    selectedCable={selectedCable}
                    show={showAddCablesModal}
                    refetch={CablesQuery.refetch}
                    onHide={() => {
                        setSelectedCable(null);
                        setShowAddCablesModal(false);
                    }}
                />
            )}
            {showRemoveCablesModal && (
                <ConfirmationDialog
                    show={showRemoveCablesModal}
                    onHide={() => {
                        setShowRemoveCablesModal(false);
                        setSelectedCable(null);
                        CablesQuery.refetch();
                    }}
                    onConfirm={async () => deleteMutation.mutateAsync()}
                    icon={NotificationIcon.Warning}
                    title="Excluir Configuração de Cubagem"
                    text="Deseja excluir a configuração de cubagem selecionada?"
                />
            )}
        </>
    );
}
