import { Form, InputGroup, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import { ModulesFilterProps } from '..';

interface ModulesFilterSectionProps {
    control: Control<ModulesFilterProps>;
}

export default function ModulesFilterSection({
    control,
}: ModulesFilterSectionProps) {
    return (
        <Row>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-search" />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Controller
                    as={
                        <Form.Control placeholder="Digite aqui o que procura..." />
                    }
                    type="text"
                    name="filterTerm"
                    control={control}
                    defaultValue=""
                />
            </InputGroup>
        </Row>
    );
}
