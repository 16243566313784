import { useRef, useState } from 'react';
import { Button, Col, Dropdown, Row, Spinner, Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useInfiniteQuery, useMutation } from 'react-query';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';
import NotificationIcon from '../../../../constants/notificationIcon';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import { StyledTh } from '../styles';
import InvertersFilterSection from './FilterSection';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import InvertersCubageModal from './InvertersModal';
import CubageHttpService, {
    ListCubageParams,
} from '../../../../services/http/cubage-http';
import ProductGroup from '../../../../constants/ProductGroup';
import handleResponseError from '../../../../utils/handleResponseError';
import InvertersCubage from '../../../../shared/interfaces/inverters-cubage.interface';

export interface InvertersFilterProps {
    filterTerm: string;
}

export default function InvertersCubageTable() {
    const rowsPerPage = 20;
    const [showAddInvertersModal, setShowAddInvertersModal] =
        useState<boolean>(false);
    const [showRemoveInvertersModal, setShowRemoveInvertersModal] =
        useState<boolean>(false);
    const [selectedInverter, setSelectedInverter] =
        useState<InvertersCubage | null>(null);

    const form = useForm<InvertersFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
        },
    });

    const formData = form.watch();
    const termTrimmed = formData.filterTerm.trim();

    const InvertersQuery = useInfiniteQuery({
        queryKey: ['inverters-cubage', termTrimmed],
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: async ({ pageParam = 0 }) => {
            const params: ListCubageParams = {
                skip: pageParam,
                take: rowsPerPage,
                term: termTrimmed || '',
            };

            const response = await CubageHttpService.readMany(
                ProductGroup.InvertersCubage,
                params,
            );

            return {
                data: response.data.results,
                currentPage: pageParam,
                pages: Math.ceil(response.data.metadata.total / rowsPerPage),
            };
        },
        getNextPageParam: ({ currentPage, pages }) =>
            currentPage + 1 < pages ? currentPage + 1 : null,
        onError: (err) => {
            handleResponseError(err, 'Erro ao buscar inversores');
        },
    });

    const target = useRef(null);

    useIntersectionObserver({
        target,
        onIntersect: InvertersQuery.fetchNextPage,
        enabled: InvertersQuery.hasNextPage,
    });

    const showLoading =
        InvertersQuery.isLoading ||
        InvertersQuery.isFetchingNextPage ||
        !InvertersQuery.isFetched;

    const deleteMutation = useMutation(
        async () => {
            if (!selectedInverter?.id) {
                throw new Error('Nenhum inversor selecionado para exclusão');
            }

            const response = await CubageHttpService.delete(
                ProductGroup.InvertersCubage,
                selectedInverter?.id,
            );

            return response.data;
        },
        {
            onError: (error: any) => {
                handleResponseError(
                    error,
                    'Erro ao excluir cubagem do inversor',
                );
            },
            onSuccess: async () => {
                toast.success('Cubagem do inversor removido com sucesso');

                InvertersQuery.refetch();
            },
        },
    );

    const InterverCubages = InvertersQuery.data?.pages.reduce(
        (accumulator, current) => accumulator.concat(current.data),
        [] as InvertersCubage[],
    );

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Cubagem dos inversores
                    </StyledPageTitle>
                </Col>
                <Button
                    className="float-right w-20 m-2"
                    style={{ width: '180px' }}
                    onClick={() => {
                        setShowAddInvertersModal(true);
                    }}
                >
                    Nova configuração
                </Button>
            </Row>
            <InvertersFilterSection control={form.control} />
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Código ERP</StyledTh>
                            <StyledTh>Cubagem</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {InterverCubages &&
                            InterverCubages.map(
                                (inverterCubage: InvertersCubage) => (
                                    <tr key={inverterCubage.id}>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[termTrimmed]}
                                                textToHighlight={inverterCubage?.id.toString()}
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[termTrimmed]}
                                                textToHighlight={inverterCubage.erpProductCode.toString()}
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[termTrimmed]}
                                                textToHighlight={inverterCubage.cubage.toString()}
                                            />
                                        </td>
                                        <td>
                                            <Dropdown key="left">
                                                <Dropdown.Toggle
                                                    bsPrefix="nexen"
                                                    className="mt-n3 mb-n3"
                                                    as={Button}
                                                    variant="text"
                                                >
                                                    <ReactMoreIcon
                                                        fill="#bdbdbd"
                                                        width="10"
                                                        height="20"
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedInverter(
                                                                inverterCubage,
                                                            );

                                                            setShowAddInvertersModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Editar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedInverter(
                                                                inverterCubage,
                                                            );

                                                            setShowRemoveInvertersModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Remover
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ),
                            )}
                    </tbody>
                </Table>
            </Row>
            <Row className="mx-4" ref={target}>
                <br />
                {showLoading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {InterverCubages?.length === 0 && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showAddInvertersModal && (
                <InvertersCubageModal
                    selectedInverter={selectedInverter}
                    show={showAddInvertersModal}
                    refetch={InvertersQuery.refetch}
                    onHide={() => {
                        setSelectedInverter(null);
                        setShowAddInvertersModal(false);
                    }}
                />
            )}
            {showRemoveInvertersModal && (
                <ConfirmationDialog
                    show={showRemoveInvertersModal}
                    onHide={() => {
                        setShowRemoveInvertersModal(false);
                        setSelectedInverter(null);
                        InvertersQuery.refetch();
                    }}
                    onConfirm={async () => deleteMutation.mutateAsync()}
                    icon={NotificationIcon.Warning}
                    title="Excluir Configuração de Cubagem"
                    text="Deseja excluir a configuração de cubagem selecionada?"
                />
            )}
        </>
    );
}
