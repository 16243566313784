import React from 'react';
import { Switch } from 'react-router-dom';
import { List } from '../../pages/Configurations';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const configPointsRoute = new Route('/configurations/points');
export const configPaymentRoute = new Route('/configurations/payments');
export const configBannersRoute = new Route('/configurations/banners');
export const configContactsRoute = new Route('/configurations/contacts');
export const configOnlineStoreRoute = new Route('/configurations/onlinestore');
export const configProductsRoute = new Route('/configurations/products');
export const configBranchesRoute = new Route('/configurations/branches');
export const configReturnUsersRoute = new Route('/configurations/returnUsers');
export const configSalesCampaignRoute = new Route('/configurations/sales');
export const configInsuranceRoute = new Route('/configurations/insurance');
export const configCubageRoute = new Route('/configurations/cubage');

export const ConfigurationsRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={configPointsRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configPaymentRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configOnlineStoreRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configBannersRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configContactsRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configBranchesRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configProductsRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configReturnUsersRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configSalesCampaignRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configInsuranceRoute.path}
            component={List}
            isPrivate
            exact
        />

        <RouteWrapper
            path={configCubageRoute.path}
            component={List}
            isPrivate
            exact
        />
    </Switch>
);
